<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl6>
        <v-overlay :value="overlay" v-if="loading">
          <v-progress-circular :size="100" color="white" indeterminate
            >Loading</v-progress-circular
          >
        </v-overlay>
        <v-card v-if="error">
          <v-alert elevation="8" type="error">{{ error }}</v-alert>
        </v-card>
        <v-card class="mb-10" v-if="showForm" elevation="12">
          <v-app-bar id="top" flat color="primary">
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>
            <v-toolbar-title class="title white--text pl-0">
              {{ currWorkOrder.taskType.description }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-card-text v-if="success" class="text-center">
            <v-alert elevation="4" class="text-left" v-show="success" type="success">{{
              successText
            }}</v-alert>
            <v-btn
              color="primary"
              class="pa-2"
              @click="$router.push({ name: 'select' })"
            >
              <v-icon class="pr-4">mdi-arrow-left-circle</v-icon>
              Return To Search
            </v-btn>
          </v-card-text>
          <v-card-text
            v-show="
                currWorkOrder.taskType.attachments &&
                  currWorkOrder.taskType.attachments.length > 0
              "
          >
            <v-row>
              <v-col class="col-3">
                <v-chip
                  class="ma-2"
                  color="orange"
                  label
                  text-color="white"
                >
                  <v-icon left>
                    mdi-danger
                  </v-icon>
                  Notice
                </v-chip>
              </v-col>
              <v-col>
              <h3>Additional documents are required to complete this work order.</h3>
              <em>Instructions will be sent by email once this work order has been
              submitted.</em>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-alert elevation="4" v-show="!valid" type="error">{{
              validationText
            }}</v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="pa-0">
                <v-col>
                  <strong>Building:</strong><br />
                  {{ currWorkOrder.building.partnerId }}
                  {{ currWorkOrder.building.name }}
                </v-col>
                <v-col>
                  <strong>SWO: </strong><br />
                  {{ currWorkOrder.partnerId }}
                </v-col>
              </v-row>
              <v-row class="pa-0">
                <v-col>
                  <strong>Asset: </strong><br />
                  {{ currWorkOrder.asset.name }}
                  <span v-show="currWorkOrder.asset.partnerId !== 'NA'">
                  <em>{{ currWorkOrder.asset.partnerId}}</em>
                  </span>
                </v-col>
                <v-col>
                  <strong>Chief Engineer: </strong><br />
                  {{ currWorkOrder.chief.name }}
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
              <v-row
                class="pa-0 mt-0"
                v-if="currWorkOrder.taskType.aggregation === 'multiple'"
              >
                <v-col>
                  <p>
                    <em v-if="!currCategory"
                      >Select a period to record rounds and readings.</em
                    >
                  </p>
                  <p v-if="currCategory">
                    Comments are required for all tasks and must be entered
                    while on-site.
                  </p>
                  <v-select
                    v-model="currCategory"
                    :items="categories"
                    label="Period"
                  ></v-select>
                </v-col>
              </v-row>
              <v-card class="mt-4" flat v-for="(item, i) in currTasks" :key="i">
                    {{ item.description }}
                      <v-row
                        v-if="showRadios"
                        d-flex
                        class="ma-0 pa-0"
                        >
                      <v-checkbox
                        class="mr-2 pa-0"
                        d-flex
                        flex-column
                        justify-start
                        @change="validateComment(i)"
                        :error-messages="statusErrors[i]"
                        v-model="item.status"
                        label="open"
                        value="open"
                        color="error"></v-checkbox>
                      <v-checkbox
                        class="mr-2 pa-0"
                        d-flex
                        flex-column
                        justify-start
                        @change="validateComment(i)"
                        :error-messages="statusErrors[i]"
                        hide-details
                        v-model="item.status"
                        label="closed"
                        value="closed"
                        color="success"></v-checkbox>
                      <v-checkbox
                        class="mr-2 pa-0"
                        d-flex
                        flex-column
                        justify-start
                        @change="validateComment(i)"
                        :error-messages="statusErrors[i]"
                        hide-details
                        v-model="item.status"
                        label="not applicable"
                        value="not applicable"
                        color="grey"></v-checkbox>
                      </v-row>
                    <v-textarea
                      counter
                      outlined
                      rows="3"
                      @change="validateComment(i)"
                      :error-messages="commentErrors[i]"
                      v-model="item.comment"
                      label="Enter comments here"
                      class="mt-2"
                    ></v-textarea>
                    <v-divider></v-divider>
              </v-card>
              <v-row class="mt-2">
                <v-col>
                  <v-btn
                    :disabled="
                      categories.length > 0 && currCategory.length === 0
                    "
                    color="primary"
                    class="mr-4"
                    @click="validate"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    color="secondary"
                    class="mr-4"
                    @click="$router.push({ name: 'select' })"
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");
// import * as easings from 'vuetify/es5/services/goto/easing-patterns';
// const FileDownload = require('js-file-download');

export default {
  name: "WorkOrderForm",
  data: () => ({
    showForm: false,
    overlay: true,
    loading: false,
    error: false,
    currWorkOrder: null,
    valid: true,
    validationText: "Validation errors have been found.",
    success: false,
    successText: "",
    categories: [],
    currTasks: [],
    currTaskIndex: {},
    currCategory: "",
    commentErrors: [],
    statusErrors: []
  }),
  created: function() {
    this.loading = true;
    axios
      .get(`/api/workorder/${this.$route.params.id}`)
      .then(res => {
        if (res.status === 200) {
          this.enableForm(res.data);
        } else {
          this.error = res.statusText;
        }
      })
      .catch(err => {
        this.error = err;
      })
      .finally(() => (this.loading = false));
  },
  watch: {
    currCategory: function(val) {
      this.currTasks = this.currTaskIndex[val];
    }
  },
  computed: {
    showRadios: function() {
      return this.currWorkOrder.taskType.aggregation !== "multiple";
    }
  },
  methods: {
    enableForm: function(wo) {
      const now = new Date();
      this.showForm = true;
      this.currWorkOrder = wo;
      if (wo.taskType.aggregation === "multiple") {
        wo.tasks.forEach(t => {
          if (this.categories.indexOf(t.category) < 0) {
            const d = new Date(t.category);
            if(d < now) {
              this.categories.push(t.category);
            }
          }

          if (!this.currTaskIndex[t.category]) {
            this.currTaskIndex[t.category] = [];
          }
          this.currTaskIndex[t.category].push(t);
        });

        if(wo.taskType.frequency === 'daily') {
          /**
           * Set the current category if it is today for daily
           */
          const d = new Date().toLocaleDateString();
          if (this.categories.indexOf(d) > -1) {
            this.currCategory = d;
          }
        } else if (wo.taskType.frequency === 'weekly') {

          /**
           * Set the current category if it is today for daily
           */
          const d = new Date(new Date() - (new Date().getDay() - 1) * 1000 * 60 *60 * 24).toLocaleDateString();
          if (this.categories.indexOf(d) > -1) {
            this.currCategory = d;
          }
        } else if (wo.taskType.frequency === 'bi-weekly') {
          /**
           * Set the current category if it is today for daily
           */
          const d = new Date(new Date() - (new Date().getDay() - 1) * 1000 * 60 *60 * 24).toLocaleDateString();
          if (this.categories.indexOf(d) > -1) {
            this.currCategory = d;
          }
        }
      } else {
        this.currTasks = wo.tasks;
      }
    },
    validateComment: function(i) {
      if (this.currWorkOrder.taskType.aggregation === "single") {
        const t = this.currWorkOrder.tasks[i];
        if(!t.status || t.status === '') {
          this.statusErrors[i] = "Please select a status";
        } else if (t.status === "open" && (!t.comment || t.comment.length < 1)) {
          this.commentErrors[i] = "Comments are required for open tasks";
          this.statusErrors[i] = undefined;
        } else {
          this.commentErrors[i] = undefined;
          this.statusErrors[i] = undefined;
        }
      } else {
        const t = this.currTasks[i];
        if (!t.comment || t.comment.length < 1) {
          t.status = "open";
          this.commentErrors[i] =
            "Comments are required for rounds and readings.";
        } else {
          t.status = "closed";
          this.commentErrors[i] = undefined;
        }
      }
    },
    validate() {
      this.$refs.form.validate();

      this.commentErrors = [];
      this.statusErrors = [];
      // make sure there is a
      // comment for all open tasks
      this.currTasks.forEach((t, i) => {
        if (this.currWorkOrder.taskType.aggregation === "single") {
          if (
            t.status === "open" &&
            (t.comment === undefined || t.comment.length === 0)
          ) {
            this.valid = false;
            this.validationText = "Validation errors have been found.";
            this.commentErrors[i] = "Comments are required for open tasks.";
          } else {
            this.commentErrors[i] = undefined;
          }
        } else {
          if (t.comment === undefined || t.comment.length === 0) {
            this.valid = false;
            this.validationText = "Validation errors have been found.";
            this.commentErrors[i] =
              "Comments are required for rounds and readings.";
          } else {
            this.commentErrors[i] = undefined;
          }
        }
      });

      if (this.valid) {
        this.submitForm();
      } else {
        this.$vuetify.goTo("#top", {
          duration: 300,
          offset: 20,
          easing: "easeInOutCubic"
        });
      }
    },
    submitForm() {
      if (!this.valid) return;
      this.loading = true;
      if (this.currWorkOrder.taskType.aggregation === "multiple") {
        this.currWorkOrder.status = "pending";
        this.currTaskIndex[this.currCategory] = this.currTasks;
        this.currWorkOrder.tasks = Object.values(this.currTaskIndex);
        this.currWorkOrder.tasks = this.currWorkOrder.tasks.flat();
      } else {
        this.currWorkOrder.status = "submitted";
      }
      axios
        .put(
          `/api/workorder/${this.currWorkOrder._id}?notify=true`,
          this.currWorkOrder
        )
        .then(res => {
          if (res.status === 200) {
            //this.currWorkOrder = res.data;
            //FileDownload(res.data, 'report.pdf');
            this.validationText = false;
            this.success = true;
            this.successText = "This work order has been submitted.";
          } else {
            this.valid = false;
            this.validationText = "Submission attempt failed.";
          }
        })
        .catch((err) => {
          this.valid = false;
          if (err.response) {
            this.validationText = `${err.response.data.message}`;
          } else if (err.request) {
            this.validationText = `Error: The work order could not be submitted.`;
          } else {
            this.validationText = `An unexpected error has occurred.`;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      this.$vuetify.goTo("#top", {
        duration: 300,
        offset: 20,
        easing: "easeInOutCubic"
      });
    }
  }
};
</script>
